.App {
  text-align: center;
}

.App-logo {
  height: 64px;
  pointer-events: none;
  margin-top: 87px;
}

.App-title {
  font-size: 24px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: -25px;
}

.App-accent {
  font-size: 24px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #4789EB;
}

.App-subtitle {
  font-size: 16px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: -5px;
}

.App-subtitle-small {
  font-size: 16px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: 6px;
}

.App-social {
  width: 24px;
  height: 24px;
  margin-right: 42px;
  cursor: pointer;
}

.App-social-end {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.App-paragraph {
  font-size: 12px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.App-sub-subtitle {
  font-size: 16px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: -30px;
}

.App-box {
  cursor: pointer;
  display: inline-block;
  max-width: 350px;
  max-height: 38px;
  padding: 16px 35px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  background: -moz-linear-gradient(-90deg, #4789EB, rgba(144, 181, 238, 0.3));
  background: -webkit-linear-gradient(-90deg, #4789EB, rgba(144, 181, 238, 0.3));
  -moz-background-origin: border;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow:
    inset 0 0 12px #144672,
    0 0 12px #144672,
    inset -999px 0 0 #144672;
}

.App-box-small {
  cursor: pointer;
  display: inline-block;
  max-width: 350px;
  max-height: 38px;
  padding: 5px 35px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  background: -moz-linear-gradient(-90deg, #4789EB, rgba(144, 181, 238, 0.3));
  background: -webkit-linear-gradient(-90deg, #4789EB, rgba(144, 181, 238, 0.3));
  -moz-background-origin: border;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow:
    inset 0 0 12px #144672,
    0 0 12px #144672,
    inset -999px 0 0 #144672;
}

.App-header {
  background-image: linear-gradient(210deg,#000 40%, #144672 );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
